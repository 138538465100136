@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem /* 32px */;
  padding-left: 2rem /* 32px */;
}
@media (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
}

.hero-section {
  background-image: url(/public/images/heroImg.png);
  background-size: cover;
}
.donate-section {
  background-image: url(/public/images/donateUs.png);
  background-size: cover;
  margin-top: 0;
  /* opacity: 0.7; */
}
.custom-button {
  width: 100%;
}
.vm-card {
  transition: box-shadow 0.3s ease-out, border-color 0.3s ease,
    transform 0.3s ease; /* Add transition for smooth effect */
  border-top: 4px solid transparent; /* Initially transparent border */
}

.vm-card:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5); /* Apply shadow on hover */
  border-color: #1f0954; /* Change border color on hover */
  transform: translateY(-5px); /* Move the card slightly upwards on hover */
}

@media (max-width: 768px) {
  .become_part {
    display: none;
  }
}
.wrapper {
  /* position: fixed;
  top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
}

.ab {
  display: block;
  width: 200px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-family: sans-serif;
  text-decoration: none;
  color: #5440f5;
  border: 2px solid #5440f5;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  transition: all 0.35s;
}

.ab span {
  position: relative;
  z-index: 2;
}

.ab:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #5440f5;
  transition: all 0.35s;
}

.ab:hover {
  color: #fff;
}

.ab:hover:after {
  width: 100%;
}
.abc {
  display: block;
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-family: sans-serif;
  text-decoration: none;
  color: #6f00fe;
  border: 2px solid #6f00fe;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  transition: all 0.35s;
}

.abc span {
  position: relative;
  z-index: 2;
}

.abc:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #6f00fe;
  transition: all 0.35s;
}

.abc:hover {
  color: #fff;
}

.abc:hover:after {
  width: 100%;
}

.card {
  position: relative;
  /* width: 220px;
  height: 320px; */
  background: mediumturquoise;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  border-radius: 15px;
  cursor: pointer;
}

.card::before,
.card::after {
  position: absolute;
  content: "";
  width: 20%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  background-color: lightblue;
  transition: all 0.5s;
}

.card::before {
  top: 0;
  right: 0;
  border-radius: 0 15px 0 100%;
}

.card::after {
  bottom: 0;
  left: 0;
  border-radius: 0 100% 0 15px;
}

.card:hover::before,
.card:hover:after {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  transition: all 0.5s;
}

.card:hover:after {
  content: "HELP PROJECT ARTHA MAKE A DIFFERENCE";
}

.whoWeBox {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
